import {
	meta
} from 'lib/dom.js'

import {
	environment
} from 'lib/environment.js'

/*
 * This is copy/pasted from MoEngage.
 */
/* eslint-disable */
(function(i,s,o,g,r,a,m,n) {
	i.moengage_object=r;
	var t={};
	var n;
	var q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};
	var f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],
			h={onsite:["getData","registerCallback"]};
	for(var k in f){t[f[k]]=q(f[k])}for(k in h)for(var l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);
	m=s.getElementsByTagName(o)[0];
	a.async=1;
	a.src=g;
	m.parentNode.insertBefore(a,m);
	i.moe=i.moe||function(){n=arguments[0];return t};
	a.onload=function(){if(n){i[r]=moe(n)}}
})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')
/* eslint-enable */

window.Moengage = window.moe({
	app_id: meta('tracking.moengage'),
	debug_logs: environment === 'production' ? 0 : 1
})

if (meta('tracking.external')) {
	window.Moengage.add_unique_user_id(meta('tracking.external'))
}

if (window.location.pathname === '/login') {
	window.Moengage.destroy_session()
}
